
export const CreateLocalLink = (menuItem, wordpressUrl, blogURI = 'blog/') => {
    const { url, connectedObject } = menuItem;

    
    if (url === '#') {
        return null;
    }
    
    let newUri = url.replace(process.env.WORDPRESS_URL, '') //url.replace(wordpressUrl, '');
    
    if (connectedObject && connectedObject.__typename === 'WPGraphQL_Post') {
        newUri = blogURI + newUri;
    }
    
    return newUri;
};

export const SocialMediaLinks = (path) => {
    let url = `${process.env.SITE_URL}${path}`
    return [
        { property: 'og:image',         content: url },
        { property: 'og:image:url',     content: url },
        { name: 'twitter:image',        content: url },
        { name: 'twitter:image:url',    content: url }
    ]
}