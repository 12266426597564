import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"
import Footer from './Footer'
import { StaticImage } from 'gatsby-plugin-image'

const Layout = ({ children, noFooter }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <div className="bg-image-body">
        <StaticImage 
            style={{width:'100vw', height:'100vh'}} 
            src="../images/bg-landing-port.jpg" 
            placeholder="blurred" 
            formats={['AUTO', 'WEBP', 'AVIF']}
            alt="Background Image"/>
      </div>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <div className="mx-auto mt-10">
        
        <main className="mt-16">
          {children}
        </main>
        
        {!noFooter ? <Footer />:null}
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
