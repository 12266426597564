import React, { useState } from 'react'
import { StaticQuery, graphql } from 'gatsby'

import MenuItem from './MenuItem'
import NavIcon from '../../assets/icon-nav.svg'

const MENU_QUERY = graphql`
    fragment MenuItem on WPGraphQL_MenuItem {
        id
        label
        url
        title
        target
    }

    query GETMAINMENU {
        wpgraphql {
            menuItems(where: {location: PRIMARY}) {
                nodes {
                    ...MenuItem
                }
            }
            generalSettings {
                url
            }
        }
    }
`

export const Menu = () => {

    const [open, setOpen] = useState(false)

    return (
        <StaticQuery
            query={MENU_QUERY}
            render={(data) => {
                if (data.wpgraphql.menuItems) {
                    const menuItems = data.wpgraphql.menuItems.nodes
                    const wordpressUrl = data.wpgraphql.generalSettings.wordpressUrl

                    return (
                        <div className="w-full">
                            <div className="flex flex-col max-w-screen-xl px-4 py-4 mx-auto md:items-center md:justify-between md:flex-row md:px-6 md:py-2 lg:px-8">
                                <div className="flex flex-row items-center justify-between p-2 md:hidden">
                                    <button className="rounded-lg focus:outline-none focus:shadow-outline"
                                        onClick={()=> setOpen(!open)}
                                        aria-label="Open the menu">
                                        <NavIcon aria-hidden="true" className="w-6 h-6" />
                                    </button>
                                </div>
                                <nav className={`flex-col flex-grow pb-4 md:pb-0 md:flex md:justify-center md:flex-row ${!open ? 'hidden':'flex'}`}>
                                    {
                                    menuItems && 
                                    menuItems.map((menuItem) => (
                                        <MenuItem key={menuItem.id} menuItem={menuItem} wordpressUrl={wordpressUrl} />
                                        ))
                                    }
                                </nav>
                            </div>
                        </div>
                    )
                }
                return null;
            }}
        />
    )
}

export default Menu;
