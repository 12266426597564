import * as React from "react"
import PropTypes from "prop-types"
import Menu from './Menu'
import './header.css'

const Header = ({ siteTitle }) => (
  <header className="sticky top-0 header">
    <Menu />
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
