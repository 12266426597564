
import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { OutboundLink } from 'gatsby-plugin-google-gtag'

const Footer = ({noIconsInvert}) => {
    return (
        <>
        <footer className="py-10" style={{zIndex:100, position:'relative'}}>
            <div className="container mx-auto">
                
                <div className="block text-center text-red-700 social-medial-follow-us text-md md:text-3xl">
                    F<span className="text-stretch">o</span>llo<span className="text-stretch">w</span> Us O<span className="text-stretch">n</span>
                </div>

                <div className="flex justify-center p-2 mt-3 social-media-icons">

                    <OutboundLink className="mx-2 social-icon" href="https://www.facebook.com/PREMIEREcondoms" target="_blank" rel="noreferrer">
                        <StaticImage 
                            className={`filter ${!noIconsInvert?'invert':''}`} 
                            src="../images/icn-fb.png" 
                            width={40} 
                            alt="Facebook Icon" />
                    </OutboundLink>

                    <OutboundLink className="mx-2 social-icon" href="https://www.instagram.com/premierecondoms" target="_blank" rel="noreferrer">
                        <StaticImage 
                            className={`filter ${!noIconsInvert?'invert':''}`} 
                            src="../images/icn-ig.png" 
                            width={40} 
                            alt="Instagram Icon" />
                    </OutboundLink>

                    <OutboundLink className="mx-2 social-icon" href="https://www.youtube.com/user/PREMIEREcondoms" target="_blank" rel="noreferrer">
                        <StaticImage 
                            className={`filter ${!noIconsInvert?'invert':''}`} 
                            src="../images/icn-yt.png" 
                            width={40} 
                            alt="Youtube Icon" />
                    </OutboundLink>
                    
                </div>
            </div>
        </footer>
        </>
    )
}

export default Footer;