import React from 'react'
import { CreateLocalLink } from '../utils'
// import { UniversalLink } from './UniversalLink'
import { Link } from 'gatsby'

export const MenuItem = ({ menuItem, wordpressUrl }) => {
    return (
        <Link to={CreateLocalLink(menuItem, wordpressUrl)} 
            activeClassName="border-opacity-100" 
            className="inline-block px-4 pb-2 my-2 text-xl text-center text-white uppercase align-baseline border border-opacity-0 border-solid rounded-lg focus:outline-none focus:shadow-outline hover:border md:rounded-3xl md:mx-3">
            {menuItem.label}
        </Link>
    )
}

export default MenuItem;